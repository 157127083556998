














































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import ManageDialog from './components/ManageDialog.vue';
import { LevelInformationService } from '@/services/abilityAssessment/level-information-service';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ManageDialog }
})
export default class levelInformationManagement extends Vue {
  @Inject(LevelInformationService)
  private levelInformationService!: LevelInformationService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  // Dialog相关
  public dialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created(): Promise<void> {
    await this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 关闭Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    // const res = await this.levelInformationService.queryArticleList(this.form);
    // TODO 模拟假数据
    setTimeout(() => {
      const res = {
        records: [
          {
            id: 1,
            levelTitle: '甲乙丙',
            levelCount: 3,
            children: [
              {
                id: 11,
                key: 1728615816340,
                levelName: '甲',
                sort: 1
              },
              {
                id: 12,
                key: 1728615877468,
                levelName: '乙',
                sort: 2
              },
              {
                id: 12,
                key: 1728615877468,
                levelName: '丙',
                sort: 3
              }
            ],
            maintenanceTime: '2024-10-10 09:10:10'
          },
          {
            id: 2,
            levelTitle: 'AB',
            levelCount: 2,
            children: [
              {
                id: 21,
                key: 1728615940138,
                levelName: 'A',
                sort: 1
              },
              {
                id: 22,
                key: 1728615981714,
                levelName: 'B',
                sort: 2
              }
            ],
            maintenanceTime: '2024-10-10 09:10:10'
          }
        ],
        total: 2,
        size: 10,
        current: 1
      };
      this.loading = false;
      this.tableData = res.records;
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }, 500);
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    this.currentRow = type !== ADD ? JSON.parse(JSON.stringify(row)) : null;
    this.dialogVisible = true;
  }
  //删除
  public async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      // TODO 等接口调试
      // await this.levelInformationService.queryArticleInfo(param);
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
