






















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class SubManageDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel() { }
    @Emit() submit(data: any) {
        return data;
    }

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        levelName: '',
        sort: 1
    };
    public rules: any = {
        levelName: [
            { required: true, message: '请输入测评等级名称', trigger: 'blur' }
        ]
    };
    public labelPosition: String = 'right';
    public title: string = '';
    public loading: Boolean = false;
    public visible: boolean = true;

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
        switch (this.dialogType) {
            case ADD:
                this.title = '新增测评等级名称';
                break;
            case EDIT:
                this.title = '编辑测评等级名称';
                // 初始化表单
                this.formInfo = deepCopy(this.defaultData);
                break;
        }
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;

            this.$message({
                message: this.dialogType === ADD ? '添加成功' : '修改成功',
                type: 'success'
            });
            this.submit(this.formInfo);
            this.cancel();
        });
    }
}
